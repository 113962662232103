/* GradientCreator.css */
.gradient-creator {
    padding: 20px;
   
    margin: auto;
    max-width: 800px;
    text-align: center;
}

.gradient-creator h3 {
    margin-bottom: 10px;
}

.gradient-creator select,
.gradient-creator textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.gradient-preview {
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.copy-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.copy-btn:hover {
    background-color: #45a049;
}
