.select-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.select-element {
  width: 100%;
  padding: 12px 15px;
  padding-right: 30px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  color: #333;
  font-size: 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-element:focus {
  outline: none;
  border-bottom: 2px solid #1976d2;
}

.select-label {
  position: absolute;
  left: 0;
  top: 12px;
  color: #757575;
  transition: all 0.3s ease;
  pointer-events: none;
}

.select-element:focus + .select-label,
.select-element:not(:placeholder-shown) + .select-label {
  top: -10px;
  font-size: 12px;
  color: #1976d2;
}

.select-container::after {
  content: '▼';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #757575;
}