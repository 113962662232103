img {
  max-width: 100%; /* Ensure images are responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Optional: Adds rounded corners */
  /* Add more styles as needed */
}


/* Enhanced Image.css */

.image-container {
  max-width: 100%;
  margin: 20px 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-preview {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 20px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.image-upload-area {
  border: 2px dashed #3498db;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-upload-area:hover {
  background-color: #e8f4f8;
}

.image-upload-icon {
  font-size: 48px;
  color: #3498db;
  margin-bottom: 10px;
}

.image-upload-text {
  color: #333;
  font-size: 16px;
}

.image-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-control {
  flex: 1 1 200px;
}

.image-control label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.image-control input[type="range"],
.image-control input[type="number"],
.image-control select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.image-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.image-action-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-action-button:hover {
  background-color: #2980b9;
}

.image-result {
  margin-top: 20px;
  padding: 20px;
  background-color: #e8f4f8;
  border-radius: 4px;
}

.image-result-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.image-download-link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #2ecc71;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.image-download-link:hover {
  background-color: #27ae60;
}

/* Responsive design */
@media (max-width: 768px) {
  .image-controls {
    flex-direction: column;
  }

  .image-control {
    flex-basis: 100%;
  }

  .image-actions {
    flex-direction: column;
    gap: 10px;
  }

  .image-action-button {
    width: 100%;
  }
}