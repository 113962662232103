/* Merged Input.css */

/* Common Styles */
.input-container {
  margin-bottom: 20px;
  position: relative;
}

.input-element,
.text-input,
input,
.input-futuristic {
  width: 95%;
  padding: 12px 15px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.input-element:hover,
.text-input:hover,
input:hover,
.input-futuristic:hover {
  border-color: #b3b3b3;
  background-color: #e8e8e8;
}

.input-element:focus,
.text-input:focus,
input:focus,
.input-futuristic:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  background-color: white;
}


.input-element {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.input-element:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
}

.floating-label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #777;
  pointer-events: none;
  transition: all 0.3s ease;
}

.input-container.focused .floating-label,
.input-container .input-element:not(:placeholder-shown) + .floating-label {
  top: 0;
  font-size: 12px;
  color: #4299e1;
  background-color: white;
  padding: 0 5px;
}





/* Label Styles */
.label-input-container {
  display: flex;
  flex-direction: column;
}

.label-input-container label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.label-input-container label:focus-within {
  color: #007bff;
}

/* File Input Styles */
.file-input-container {
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.file-input-container:hover,
.file-input-container.dragging {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.05);
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: -1;
}

.file-input-label {
  display: block;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.file-input-text {
  margin-bottom: 10px;
}

.file-input-plus {
  display: inline-block;
  margin-left: 10px;
  font-size: 24px;
  line-height: 1;
  color: #007bff;
}

/* Futuristic Input Style */
.input-futuristic {
  border: 2px solid transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Animation */
@keyframes focusAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.input-element:focus,
.text-input:focus,
input:focus,
.input-futuristic:focus {
  animation: focusAnimation 0.3s ease-in-out;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .input-element,
  .text-input,
  input,
  .input-futuristic,
  .file-input-label {
    font-size: 14px;
    padding: 10px 12px;
  }

  .file-input-plus {
    font-size: 20px;
  }
}

