.resize-image-canvas-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.resize-image-canvas-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.resize-image-canvas-container input[type="file"],
.resize-image-canvas-container input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.resize-image-canvas-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.resize-image-canvas-container button:hover {
    background-color: #0056b3;
}

.resize-image-canvas-container img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
}