/* Custom Style for BulkConvertImageFormat.js */

.bulk-convert-image-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.bulk-convert-image-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

/* Custom File Input Style */
.bulk-convert-image-container .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #f8f9fa;
    margin-bottom: 20px;
    text-align: center;
}

.bulk-convert-image-container .file-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

/* Styling for Select and Button */
.bulk-convert-image-container select, 
.bulk-convert-image-container button {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #e9ecef;
    cursor: pointer;
}

.bulk-convert-image-container button {
    background-color: #007bff;
    color: white;
}

.bulk-convert-image-container button:hover {
    background-color: #0069d9;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .bulk-convert-image-container {
        width: 90%;
    }
}

@media screen and (max-width: 480px) {
    .bulk-convert-image-container {
        width: 100%;
    }

    .bulk-convert-image-container button, 
    .bulk-convert-image-container select {
        font-size: 14px;
    }
}
