.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  height: 75vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.card-group {
  margin-bottom: 20px;
}

.card-group h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  flex: 0 1 calc(33.333% - 20px);
  min-width: 250px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card a {
  display: block;
  height: 100%;
  padding: 20px;
  color: #212121;
  text-decoration: none;
}

.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card p {
  font-size: 14px;
  line-height: 1.4;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .card {
    flex: 0 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .card {
    flex: 0 1 100%;
  }
}