.bodybeautifier {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}
.iptextarea {
  width: 50vh;
  height: 40vh;
}
.isp {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 20vh;
}
.textarea {
  width: 50vh;
  height: 60vh;
}
button {
  margin: 10px;
}
.column {
  display: flex;
  flex-direction: column;
}
