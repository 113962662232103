.color-converter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  input[type="color"] {
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .color-values {
    margin-top: 20px;
    text-align: center;
  }
  
  p {
    margin: 10px 0;
    font-family: Arial, sans-serif;
    font-size: 16px;
  }
  
  /* Custom styles for color input in different browsers */
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  ::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }
  
  ::-moz-color-swatch,
  ::-moz-focus-inner {
    border: none;
  }
  
  ::-moz-focus-inner {
    padding: 0;
  }