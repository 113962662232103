.pdf-container,
.react-pdf__Document {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between thumbnails */
  padding: 10px;
  overflow-y: auto; /* Vertical scrolling if there are many thumbnails */
}

.pdf-page {
  flex: none;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.react-pdf__Page__canvas {
  width: 100px; /* Adjust width as needed */
  height: auto;
}
.textLayer,
.annotationLayer {
  display: none;
}

.pdf-handler {
  padding: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: 1px solid #dcdcdc;
  max-width: 800px;
  margin: auto;
}

.pdf-handler h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.pdf-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fff;
  max-height: 400px;
  overflow-y: auto;
}

.pdf-page {
  flex: none;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.pdf-page input[type='checkbox'] {
  margin-top: 5px;
}

/* CompressPDFs.css */

.compress-pdfs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #e8eddf;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.compress-pdfs-container h1 {
  color: #38b000;
  font-size: 24px;
  margin-bottom: 20px;
}

.compress-pdfs-container input[type="file"] {
  border: 2px solid #aacc00;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.compress-pdfs-container button {
  background-color: #38b000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.compress-pdfs-container button:hover {
  background-color: #2d7d00;
}

.compress-pdfs-container .react-pdf__Document {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.compress-pdfs-container .react-pdf__Page {
  flex: none;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .compress-pdfs-container {
      width: 100%;
padding: 10px;
}
}