/* AddWatermarkToPDF.css */

/* General Layout */
body {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    margin: 0;

}

.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Typography */
h1, h2, h3 {
    color: #5D647B;
}

p {
    line-height: 1.6;
}

/* Buttons and Inputs */
button, input[type="text"], input[type="file"] {
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
}

button {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #45a049;
}

input[type="text"], input[type="file"] {
    width: 100%;
    box-sizing: border-box;
}

/* Responsive Design */
@media (max-width: 600px) {
    .container {
        width: 100%;
        padding: 10px;
    }

    button, input[type="text"], input[type="file"] {
        width: 100%;
    }
}
