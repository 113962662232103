/* HtmlToPdf.css */

.html-to-pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #eaecef;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: auto;
}

.html-to-pdf-container h1 {
    color: #2c3e50;
    font-size: 24px;
    margin-bottom: 20px;
}

.html-to-pdf-container textarea {
    width: 100%;
    height: 150px;
    border: 2px solid #2c3e50;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    resize: vertical;
}

.html-to-pdf-container button {
    background-color: #2c3e50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.html-to-pdf-container button:hover {
    background-color: #1b2838;
}

.html-to-pdf-container .react-pdf__Document {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.html-to-pdf-container .react-pdf__Page {
    flex: none;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0 , 0, 0, 0.1);
}

@media (max-width: 600px) {
    .html-to-pdf-container {
        width: 100%;
        padding: 10px;
    }
}
