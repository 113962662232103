.json-utility-container {
  width: 100%;
  margin: 0;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
}

.json-utility-container h2 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.json-input, .json-output {
  width: 100%;
  min-height: 200px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
}

.json-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.json-button {
  padding: 0.5rem 1rem;
  background-color: #00b894;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.json-button:hover {
  background-color: #00a884;
}

.json-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.json-result {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
  overflow-x: auto;
}

.json-diff {
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-all;
}

.json-diff .diff-added {
  background-color: #e6ffed;
  color: #22863a;
}

.json-diff .diff-removed {
  background-color: #ffeef0;
  color: #cb2431;
}

.json-diff .diff-unchanged {
  color: #24292e;
}

.json-tree {
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
}

.json-tree ul {
  list-style-type: none;
  padding-left: 1.5em;
}

.json-tree .json-key {
  color: #881391;
}

.json-tree .json-string {
  color: #1a1aa6;
}

.json-tree .json-number {
  color: #116644;
}

.json-tree .json-boolean {
  color: #0000ff;
}

.json-tree .json-null {
  color: #808080;
}

.json-error {
  color: #e74c3c;
  margin-top: 1rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .json-utility-container {
    padding: 0.5rem;
  }

  .json-utility-container h2 {
    font-size: 1.5rem;
  }

  .json-controls {
    flex-direction: column;
  }

  .json-button {
    margin-bottom: 0.5rem;
  }
}
