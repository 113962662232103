/* Enhanced Image.css */

.image-utility-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.image-utility-container:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.image-utility-container h2 {
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
}

.image-input-wrapper {
  margin-bottom: 1.5rem;
}

.image-input {
  width: 100%;
  padding: 0.75rem;
  border: 2px dashed #3498db;
  border-radius: 8px;
  background-color: #e8f4f8;
  transition: all 0.3s ease;
  cursor: pointer;
}

.image-input:hover {
  background-color: #d1e8f2;
}

.image-preview {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-options {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.image-option {
  flex: 1 1 200px;
}

.image-option label {
  display: block;
  margin-bottom: 0.5rem;
  color: #34495e;
  font-weight: 600;
}

.image-option input,
.image-option select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1rem;
}

.image-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-button:hover {
  background-color: #2980b9;
}

.image-result {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #ecf0f1;
  border-radius: 8px;
}

.image-result h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.image-download {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #2ecc71;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.image-download:hover {
  background-color: #27ae60;
}

@media (max-width: 600px) {
  .image-utility-container {
    padding: 1rem;
  }

  .image-options {
    flex-direction: column;
  }

  .image-option {
    flex-basis: 100%;
  }
}