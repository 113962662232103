/* utility.css */

.utility-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .utility-container h2 {
    color: #2c3e50;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .utility-container input[type="text"],
  .utility-container input[type="number"],
  .utility-container input[type="color"],
  .utility-container select,
  .utility-container textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .utility-container input[type="file"] {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 2px dashed #3498db;
    border-radius: 4px;
    background-color: #e8f4f8;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .utility-container input[type="file"]:hover {
    background-color: #d1e8f2;
  }
  
  .utility-container button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .utility-container button:hover {
    background-color: #2980b9;
  }
  
  .utility-container button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
  
  .utility-preview {
    margin-top: 2rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 1rem;
    background-color: white;
  }
  
  .utility-result {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-wrap;
    word-break: break-all;
  }
  
  .color-preview {
    width: 100px;
    height: 100px;
    margin: 1rem auto;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .grid-item {
    background-color: #f1f3f5;
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .utility-container {
      padding: 1rem;
    }
  
    .utility-container h2 {
      font-size: 1.5rem;
    }
  }