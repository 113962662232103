/* BackgroundRemover.css */
.background-remover-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    text-align: center;
}

.background-remover-container input[type="file"] {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.background-remover-container label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
}

.background-remover-container input[type="number"] {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.background-remover-container button {
    padding: 10px 20px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

.background-remover-container button:hover {
    background-color: #0056b3!important;
}

.background-remover-container .error-message {
    color: #ff0000;
    margin-top: 15px;
}

.background-remover-container textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #007bff;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  