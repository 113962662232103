body {
  font-family: Arial, sans-serif;
  margin: 0;
  /* padding: 20px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.App {
  width: 80%;
}

/* Global Styles */
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f5f7fa;
  --text-color: #333333;
  --header-height: 60px;
}

body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-bottom: 1rem;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

p {
  margin-bottom: 1rem;
}

/* Layout */
.main-content {
  margin-top: var(--header-height);
  padding: 2rem 0;
}

/* Card Styles */
.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* padding: 1.5rem; */
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Button Styles */
.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
  background-color: #2980b9;
  color: #fff;
}

/* Form Styles */
input, textarea, select {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: box-shadow 0.3s ease;
}

input:focus, textarea:focus, select:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  outline: none;
}

/* Utility Classes */
.text-center { text-align: center; }
.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  h1 { font-size: 2rem; }
  h2 { font-size: 1.75rem; }
  h3 { font-size: 1.5rem; }
}
