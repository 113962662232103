/* Merged PDF.css */

.pdf-utility-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.pdf-utility-container:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.pdf-utility-container h2 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
}

.pdf-input-wrapper {
  margin-bottom: 1.5rem;
}

.pdf-utility-container input[type="file"] {
  width: 100%;
  padding: 0.75rem;
  border: 2px dashed #3498db;
  border-radius: 8px;
  background-color: #e8f4f8;
  transition: all 0.3s ease;
  cursor: pointer;
}

.pdf-utility-container input[type="file"]:hover {
  background-color: #d1e8f2;
}

.pdf-utility-container input[type="text"],
.pdf-utility-container input[type="number"],
.pdf-utility-container input[type="password"],
.pdf-utility-container select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.pdf-utility-container input[type="text"]:focus,
.pdf-utility-container input[type="number"]:focus,
.pdf-utility-container input[type="password"]:focus,
.pdf-utility-container select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.25);
}

.pdf-button,
.pdf-utility-container button {
  display: inline-block;
  width: 100%;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pdf-button:hover,
.pdf-utility-container button:hover {
  background-color: #2980b9;
}

.pdf-button:disabled,
.pdf-utility-container button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.pdf-preview {
  max-width: 100%;
  height: auto;
  margin: 2rem 0;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pdf-preview .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-preview .react-pdf__Page {
  max-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.pdf-preview canvas {
  max-width: 100%;
  height: auto !important;
}

.pdf-result {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #ecf0f1;
  border-radius: 8px;
}

.pdf-result h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.pdf-download {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #2ecc71;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.pdf-download:hover {
  background-color: #27ae60;
}

.pdf-pages-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.pdf-page-thumbnail {
  flex: 0 0 auto;
  width: 100px;
  height: 141px; /* Aspect ratio of 1:√2 (A4 paper) */
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pdf-page-thumbnail:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pdf-page-thumbnail.selected {
  border-color: #3498db;
  box-shadow: 0 0 0 2px #3498db;
}

@media (max-width: 768px) {
  .pdf-utility-container {
    padding: 1rem;
  }

  .pdf-utility-container h2 {
    font-size: 1.5rem;
  }

  .pdf-pages-container {
    justify-content: center;
  }
}