.back-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.back-button:hover {
    color: #0056b3;
}

.title-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.title-container h2 {
    margin: 0;
    width: 100%;
}
