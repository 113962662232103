.image-uploader-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.image-uploader-container input[type="file"] {
    margin-bottom: 15px;
}

.image-uploader-container label {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
}

.image-uploader-container input[type="number"],
.image-uploader-container input[type="checkbox"] {
    margin-bottom: 15px;
    margin-right: 5px;
}

.image-uploader-container button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 16px;
}

.image-uploader-container button:hover {
    background-color: #45a049;
}

.image-uploader-container textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: none;
}

.error-message {
    color: red;
    margin-top: 10px;
}
