.json-beautifier {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #333;
}

.main-content {
  display: flex;
  gap: 20px;
  padding: 20px;
  flex: 1;
  overflow: hidden;
}

.editor-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
}

.editor-section:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.controls-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin-right: 20px;
}

.toolbar {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.json-search-Input {
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  font-family: monospace;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.json-search-Input:focus {
  outline: none;
  border-color: #00b894;
}

.Input-Textarea,
.output-Textarea {
  flex: 1;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  overflow: auto;
}

.Input-Textarea:focus,
.output-Textarea:focus {
  outline: none;
  border-color: #00b894;
}

.Input-Textarea::placeholder,
.output-Textarea::placeholder {
  color: #999;
}

.json-view-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: auto;
  max-height: 500px;
}

.json-path-Input {
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  font-family: monospace;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.json-path-Input:focus {
  outline: none;
  border-color: #00b894;
}

.json-actions {
  display: flex;
  gap: 10px;
  margin-top: auto;
  flex-shrink: 0;
}

/* Override Button component styles */
:global(.button) {
  background-color: #00b894 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  transition: background-color 0.3s !important;
  white-space: nowrap !important;
}

:global(.button:hover) {
  background-color: #00a884 !important;
}

:global(.button:disabled) {
  background-color: #cccccc !important;
  cursor: not-allowed !important;
}

/* Override Select component styles */
:global(.select) {
  background-color: #ffffff !important;
  color: #333 !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  padding: 8px !important;
}

:global(.select:focus) {
  outline: none !important;
  border-color: #00b894 !important;
}

/* Override Input component styles */
:global(.input) {
  background-color: #ffffff !important;
  color: #333 !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  padding: 8px !important;
}

:global(.input:focus) {
  outline: none !important;
  border-color: #00b894 !important;
}

/* Override ReactJson component styles */
.react-json-view {
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 4px !important;
  font-family: monospace !important;
  font-size: 14px !important;
  flex: 1 !important;
  overflow: auto !important;
}

/* Toast container styles */
:global(.Toastify__toast) {
  background-color: #ffffff !important;
  color: #333 !important;
  border: 1px solid #e0e0e0 !important;
}

:global(.Toastify__toast--success) {
  background-color: #00b894 !important;
  color: white !important;
  border: none !important;
}

:global(.Toastify__toast--error) {
  background-color: #ff6b6b !important;
  color: white !important;
  border: none !important;
}

/* File input area */
.file-input-area {
  border: 2px dashed #e0e0e0;
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  background-color: #f8f9fa;
  margin-bottom: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

.file-input-area:hover {
  border-color: #00b894;
}
