.svg-to-png-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .svg-to-png-container h2 {
    margin-bottom: 10px;
  }
  
  .svg-to-png-container textarea {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .svg-to-png-container select {
    margin-bottom: 10px;
  }
  
  .svg-to-png-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .svg-to-png-container img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }