/* Textarea.css */

.textarea-container {
  margin-bottom: 20px;
}

.textarea-element {
  width: 100%;
  padding: 12px 15px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  font-family: inherit;
  resize: vertical;
  min-height: 100px;
  transition: all 0.3s ease;
}

.textarea-element:hover {
  border-color: #b3b3b3;
  background-color: #e8e8e8;
}

.textarea-element:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  background-color: white;
}

/* Scrollbar styles for webkit browsers */
.textarea-element::-webkit-scrollbar {
  width: 8px;
}

.textarea-element::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.textarea-element::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.textarea-element::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Animation */
@keyframes textareaFocusAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(1.01); }
  100% { transform: scale(1); }
}

.textarea-element:focus {
  animation: textareaFocusAnimation 0.3s ease-in-out;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .textarea-element {
    font-size: 14px;
    padding: 10px 12px;
  }
}