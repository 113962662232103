.route-with-footer-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px;
  width: 100%;
  position: relative;
}

.main-content {
  flex: 1;
  transition: margin-right 0.3s ease-in-out;
}

.main-content.shifted {
  margin-right: 350px;
}

.instructions-panel {
  position: fixed;
  top: 70px;
  right: -350px;
  width: 350px;
  height: calc(100vh - 70px);
  background-color: #f8f9fa;
  border-left: 1px solid #dee2e6;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.instructions-panel.open {
  right: 0;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
}

.instructions-toggle {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 1001;
  width: 60px;
  height: 60px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.instructions-toggle:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.instructions-toggle.open {
  right: 370px;
}

.instructions-panel h2 {
  padding: 20px;
  margin: 0;
  background-color: #007bff;
  color: white;
  font-size: 1.5rem;
}

.instructions-content {
  overflow-y: auto;
  padding: 20px;
  flex-grow: 1;
}

.instruction-step {
  margin-bottom: 15px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  overflow: hidden;
}

.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #e9ecef;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.step-header:hover {
  background-color: #dee2e6;
}

.step-number {
  font-weight: bold;
  font-size: 1.1rem;
}

.step-header svg {
  transition: transform 0.3s ease;
}

.step-header svg.rotated {
  transform: rotate(180deg);
}

.step-content {
  padding: 15px;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .main-content.shifted {
    margin-right: 0;
  }

  .instructions-panel {
    width: 100%;
    right: -100%;
  }

  .instructions-toggle.open {
    right: 20px;
  }
}