/* UnlockPDF.css */

.unlock-pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff3e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: auto;
}

.unlock-pdf-container h1 {
    color: #ff6f00;
    font-size: 24px;
    margin-bottom: 20px;
}

.unlock-pdf-container input[type="file"],
.unlock-pdf-container input[type="text"] {
    width: 100%;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #ffcc80;
    color: #ff6f00;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-align: center;
}

.unlock-pdf-container input[type="file"]::file-selector-button,
.unlock-pdf-container input[type="text"]::placeholder {
    display: none;
}

.unlock-pdf-container button {
    background-color: #ff6f00;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.unlock-pdf-container button:hover {
    background-color: #e65100;
}

.unlock-pdf-container .react-pdf__Document {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.unlock-pdf-container .react-pdf__Page {
    flex: none;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .unlock-pdf-container {
        width: 100%;
        padding: 10px;
    }
}
