/* Enhanced Button.css */

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Primary Button */
.button-primary {
  background-color: #3498db;
  color: #ffffff;
}

.button-primary:hover {
  background-color: #2980b9;
}

/* Secondary Button */
.button-secondary {
  background-color: #2ecc71;
  color: #ffffff;
}

.button-secondary:hover {
  background-color: #27ae60;
}

/* Outlined Button */
.button-outlined {
  background-color: transparent;
  border: 2px solid #3498db;
  color: #3498db;
}

.button-outlined:hover {
  background-color: #3498db;
  color: #ffffff;
}

/* Disabled Button */
.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Button Sizes */
.button-small {
  padding: 8px 16px;
  font-size: 14px;
}

.button-large {
  padding: 12px 24px;
  font-size: 18px;
}

/* Button with Icon */
.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-icon svg {
  margin-right: 8px;
}

/* Ripple Effect */
.button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .button-large {
    padding: 10px 20px;
    font-size: 16px;
  }
}