.markdown-preview-container {
    display: flex;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .markdown-input,
  .markdown-output {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .markdown-input textarea {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-family: monospace;
    font-size: 14px;
    resize: none;
  }
  
  .markdown-output {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .markdown-output h1,
  .markdown-output h2,
  .markdown-output h3,
  .markdown-output h4,
  .markdown-output h5,
  .markdown-output h6 {
    margin-top: 0;
  }
  
  .markdown-output pre {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .markdown-output code {
    font-family: monospace;
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 2px;
  }