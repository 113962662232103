.unix-time-converter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .unix-time-converter div {
    display: flex;
    gap: 10px;
  }
  
  .unix-time-converter input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .unix-time-converter button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .unix-time-converter button:hover {
    background-color: #45a049;
  }