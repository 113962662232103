.add-page-numbers-to-pdf-handler {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
}

.add-page-numbers-to-pdf-handler h2 {
    color: #333;
    margin-bottom: 20px;
}

.add-page-numbers-to-pdf-handler button,
.add-page-numbers-to-pdf-handler input[type="file"] {
    padding: 10px 15px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-page-numbers-to-pdf-handler button:hover,
.add-page-numbers-to-pdf-handler input[type="file"]:hover {
    background-color: #45a049;
}

.add-page-numbers-to-pdf-handler button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.add-page-numbers-to-pdf-handler input[type="file"] {
    cursor: pointer;
}

.add-page-numbers-to-pdf-handler .pdf-preview {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
}
