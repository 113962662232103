.sidebar {
    width: 250px;
    height: 100%;
    background-color: #f8f8f8;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
    overflow-y: auto;
    transition: width 0.3s ease;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo-container img {
    margin-right: 10px;
  }
  
  .logo-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .search-bar {
    position: relative;
    margin-bottom: 20px;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
  }
  
  .search-bar input {
    width: 100%;
    padding: 8px 8px 8px 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .route-group {
    margin-bottom: 15px;
  }
  
  .route-group h3 {
    font-size: 12px;
    color: #888;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .route-group ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .route-group li {
    margin-bottom: 5px;
  }
  
  .route-group a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #333;
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 14px;
  }
  
  .route-group a:hover {
    background-color: #e0e0e0;
  }
  
  .route-group a.active {
    background-color: #4caf50;
    color: white;
  }
  
  .route-group a svg {
    margin-right: 10px;
    min-width: 20px;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #333;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .sidebar {
      width: 60px;
      padding: 10px;
    }
  
    .sidebar.expanded {
      width: 250px;
    }
  
    .sidebar.collapsed .route-group h3,
    .sidebar.collapsed .route-group a span,
    .sidebar.collapsed .search-bar,
    .sidebar.collapsed .logo-text {
      display: none;
    }
  
    .sidebar.collapsed .route-group a {
      justify-content: center;
    }
  
    .sidebar.collapsed .route-group a svg {
      margin-right: 0;
    }
  
    .logo-container {
      justify-content: center;
    }
  }


  
  .sidebar {
    width: 250px;
    height: 100vh;
    background-color: #f8f8f8;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
    overflow-y: auto;
    transition: width 0.3s ease;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo-container img {
    margin-right: 10px;
  }
  
  .logo-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .search-bar {
    position: relative;
    margin-bottom: 20px;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
  }
  
  .search-bar input {
    width: 100%;
    padding: 8px 8px 8px 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .route-group {
    margin-bottom: 15px;
  }
  
  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
  }
  
  .category-header h3 {
    font-size: 14px;
    color: #888;
    text-transform: uppercase;
    margin: 0;
  }
  
  .category-header svg {
    transition: transform 0.3s ease;
  }
  
  .category-header:hover {
    background-color: #f0f0f0;
  }
  
  .route-group ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .route-group.expanded ul {
    max-height: 1000px; /* Adjust this value based on your needs */
  }
  
  .route-group li {
    margin-bottom: 5px;
  }
  
  .route-group a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #333;
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 14px;
  }
  
  .route-group a:hover {
    background-color: #e0e0e0;
  }
  
  .route-group a.active {
    background-color: #4caf50;
    color: white;
  }
  
  .route-group a svg {
    margin-right: 10px;
    min-width: 20px;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #333;
    font-size: 24px;
    cursor: pointer;
  }
  
  .sidebar {
    width: 250px;
    height: 94vh;
    background-color: #f8f8f8;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
    overflow-y: auto;
    /* scrollbar-width: none;  */
    scrollbar-width: thin;
    scrollbar-color: rgba(203, 199, 199, 0.419) rgba(220, 216, 216, 0);
    transition: width 0.3s ease;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo-container img {
    margin-right: 10px;
  }
  
  .logo-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .search-bar {
    position: relative;
    margin-bottom: 20px;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
  }
  
  .search-bar input {
    width: 100%;
    padding: 8px 8px 8px 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .route-group {
    margin-bottom: 15px;
  }
  
  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
  }
  
  .category-header h3 {
    font-size: 14px;
    color: #888;
    text-transform: uppercase;
    margin: 0;
  }
  
  .category-header svg {
    transition: transform 0.3s ease;
  }
  
  .category-header:hover {
    background-color: #f0f0f0;
  }
  
  .route-group .sub-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .route-group.expanded .sub-menu {
    max-height: 1000px; /* Adjust this value based on your needs */
  }
  
  .route-group .sub-menu li {
    margin-bottom: 5px;
  }
  
  .route-group .sub-menu a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #333;
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 14px;
  }
  
  .route-group .sub-menu a:hover {
    background-color: #e0e0e0;
  }
  
  .route-group .sub-menu a.active {
    background-color: #4caf50;
    color: white;
  }
  
  .route-group .sub-menu a svg {
    margin-right: 10px;
    min-width: 20px;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #333;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .sidebar {
      width: 60px;
      padding: 10px;
    }
  
    .sidebar.expanded {
      width: 250px;
    }
  
    .sidebar.collapsed .route-group h3,
    .sidebar.collapsed .route-group a span,
    .sidebar.collapsed .search-bar,
    .sidebar.collapsed .logo-text {
      display: none;
    }
  
    .sidebar.collapsed .route-group a {
      justify-content: center;
    }
  
    .sidebar.collapsed .route-group a svg {
      margin-right: 0;
    }
  
    .logo-container {
      justify-content: center;
    }
  }