.foot{
    opacity: 1;
    transform: none;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 26px;
}

.foot {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
