button:hover {
  /* background: none !important; */
}

.pdf-container,
.react-pdf__Document {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between thumbnails */
  padding: 10px;
  overflow-y: auto; /* Vertical scrolling if there are many thumbnails */
}

.pdf-page {
  flex: none;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.react-pdf__Page__canvas {
  width: 100px; /* Adjust width as needed */
  height: auto;
}
.textLayer,
.annotationLayer {
  display: none;
}

.pdf-handler {
  padding: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: 1px solid #dcdcdc;
  max-width: 800px;
  margin: auto;
}

.pdf-handler h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.pdf-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fff;
  max-height: 400px;
  overflow-y: auto;
}

.pdf-page {
  flex: none;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.pdf-page input[type='checkbox'] {
  margin-top: 5px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}

button:hover {
  background-color: #45a049 !important;
}

input[type='file'] {
  margin-bottom: 15px;
}

/* Additional styles for drag and drop area */
.drag-drop-area {
  border: 2px dashed #007bff;
  padding: 20px;
  border-radius: 8px;
  margin: 10px 0;
  background-color: #e6f7ff;
  color: #007bff;
  cursor: pointer;
}

.drag-drop-area:hover {
  background-color: #cfe2ff;
}

/* Styles for readonly input displaying selected pages */
.selected-pages-input {
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

/* PDFHandler.css */

.pdf-handler-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
}

.pdf-handler-container h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.pdf-handler-container input[type="file"] {
  width: 100%;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #e6e6e6;
  color: #333;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
}

.pdf-handler-container input[type="file"]::file-selector-button {
  display: none;
}

.pdf-handler-container button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pdf-handler-container button:hover {
  background-color: #262626;
}

.pdf-handler-container .react-pdf__Document {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.pdf-handler-container .react-pdf__Page {
  flex: none;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .pdf-handler-container {
      width: 100%;
      padding: 10px;
  }
}


  