.pdf-container,
.react-pdf__Document {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between thumbnails */
  padding: 10px;
  overflow-y: auto; /* Vertical scrolling if there are many thumbnails */
}

.pdf-page {
  flex: none;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.react-pdf__Page__canvas {
  width: 100px; /* Adjust width as needed */
  height: auto;
}
.textLayer,
.annotationLayer {
  display: none;
}

.pdf-handler {
  padding: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: 1px solid #dcdcdc;
  max-width: 800px;
  margin: auto;
}

.pdf-handler h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.pdf-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fff;
  max-height: 400px;
  overflow-y: auto;
}

.pdf-page {
  flex: none;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.pdf-page input[type='checkbox'] {
  margin-top: 5px;
}

/* ExtractPDFPages.css */

.extract-pdf-pages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff3cd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
}

.extract-pdf-pages-container h1 {
  color: #856404;
  font-size: 24px;
  margin-bottom: 20px;
}

.extract-pdf-pages-container input[type="file"] {
  width: 100%;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #ffeeba;
  color: #856404;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
}

.extract-pdf-pages-container input[type="file"]::file-selector-button {
  display: none;
}

.extract-pdf-pages-container button {
  background-color: #856404;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.extract-pdf-pages-container button:hover {
  background-color: #704c00;
}

.extract-pdf-pages-container .react-pdf__Document {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.extract-pdf-pages-container .react-pdf__Page {
  flex: none;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .extract-pdf-pages-container {
      width: 100%;
      padding: 10px;
  }
}
